const React = require('react');
const { bool, shape, string } = require('prop-types');
const { loadable } = require('nordic/lazy');
const { RecommendationsCarouselSnapped } = require('@recommendations-fe/carousel-snapped');
const RecommendationsIconFactory = require('./icon-factory');
const ContextTemplate = require('./polycard-context');
const { RecommendationsCardHorizontalTitleFirst } = require('./recomendations-card');

const Polycard = loadable(() => import('../polycard/polycard'));

Polycard.preload();

const Recommendations = ({ isPolycard, thousandSeparator, decimalSeparator, ...props }) => {
  const CardTemplate = cardProps =>
    isPolycard ? (
      <Polycard polycard={{ ...cardProps }} className="recos-polycard poly-card--mobile" />
    ) : (
      <RecommendationsCardHorizontalTitleFirst {...cardProps} />
    );

  return (
    <RecommendationsCarouselSnapped
      Card={CardTemplate}
      Context={ContextTemplate}
      contextProps={{
        type: 'list-card',
        thousandSeparator,
        decimalSeparator,
      }}
      isPolycard={isPolycard}
      IconFactory={RecommendationsIconFactory}
      {...props}
    />
  );
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  className: string,
  apiresponse: shape({}),
};

module.exports = Recommendations;
